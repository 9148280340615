import Lottie from 'react-lottie-player';
import lottieJson from './lottie/72179-chart-animation.json';
import styles from "./css/dark-theme.module.css";
import React, {Component} from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CryptoCoinsHeatmapTradingView from './components/CryptoCoinsHeatmapTradingView';
import loadingLottieJson from "./lottie/animation_lkfxk9f1";
import EconomicCalendarScriptTradingView from "./components/EconomicCalendarScriptTradingView";
import ScreenerScriptTradingView from "./components/ScreenerScriptTradingView";
import axios from "axios";

const authKey = process.env.REACT_APP_AUTH_KEY;
const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
const nodeEnv = process.env.REACT_APP_NODE_ENV;
let serverUrl = 'Check env';
if (nodeEnv === 'dev') {
    serverUrl = localServerUrl;
}
if (nodeEnv === 'prod') {
    serverUrl = prodServerUrl;
}


class Metrics extends Component {

    state = {
        isLoaded: false,
        apiData: [],
        script: document.createElement("script"),
    };

    async componentDidMount() {
        const packets = {
            authToken: authKey,
        };
        try {
            await axios.post(serverUrl + '/apiv2/g/lastblogs', packets, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({ apiData: response.data.data, isLoaded: true,});
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const lottieWidth = window.innerWidth * 15 / 100;

        if (!this.state.isLoaded) {
            return (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4}> </Grid>
                    <Grid item xs={12} lg={4}>
                        <Lottie
                            loop
                            animationData={loadingLottieJson}
                            play
                            style={{width: lottieWidth, align:"center", }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}> </Grid>
                </Grid>
            );
        }

        return (
            <Box sx={{flexGrow: 1}} p={1}>
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4} className={styles.homepageSecondBlock} ml={2}>
                        <h2>All crypto metrics on 1 page.</h2>
                        <small>
                            - Economic Calendar
                            <br></br>
                            - Crypto Coins Heatmap
                            <br></br>
                            - Screener
                        </small>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Lottie
                            loop
                            animationData={lottieJson}
                            play
                            style={{width: lottieWidth, align: "center", margin: "0 0 0 100px"}}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={12}>
                        <div className={styles.homepageSecondBlock}>
                            <EconomicCalendarScriptTradingView/>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <CryptoCoinsHeatmapTradingView/>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <ScreenerScriptTradingView/>
                    </Grid>
                </Grid>
            </Box>

        );
    }
}
export default Metrics;