import Lottie from 'react-lottie-player';
import styles from "./css/dark-theme.module.css";
import React, {Component} from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';
import loadingLottieJson from "./lottie/animation_lkfxk9f1";
import {Helmet} from "react-helmet";
import {Telegram, Twitter, YouTube} from "@mui/icons-material";

const authKey = process.env.REACT_APP_AUTH_KEY;
const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
const nodeEnv = process.env.REACT_APP_NODE_ENV;
let serverUrl = 'Check env';
if (nodeEnv === 'dev') {
    serverUrl = localServerUrl;
}
if (nodeEnv === 'prod') {
    serverUrl = prodServerUrl;
}

class AirdropPage extends Component {

    state = {
        isLoaded: false,
        apiData: [],
        serverUrl: '',
        pageLink: '',
    };
    async componentDidMount() {
        const pageLink = window.location.pathname.slice(10);
        const packets = {
            authToken: authKey,
        };
        try {
            await axios.post(serverUrl + '/apiv2/airdropid/' + pageLink, packets, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({ apiData: response.data.data, isLoaded: true, serverUrl: serverUrl, pageLink: pageLink,});
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {

        const lottieWidth = window.innerWidth * 30 / 100;
        const imageWidth = window.innerWidth * 75 / 100;

        if (!this.state.isLoaded) {
            return (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4}> </Grid>
                    <Grid item xs={12} lg={4}>
                        <Lottie
                            loop
                            animationData={loadingLottieJson}
                            play
                            style={{width: lottieWidth, align:"center", }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}> </Grid>
                </Grid>
            );
        }
        const item = this.state.apiData['airdropArticle'];
        const server = this.state.serverUrl;
        const pageLink = this.state.pageLink;
        let url = server+'/airdrop/'+pageLink;
        let publishedDate = new Date(item.created_at);
        let finishDate = new Date(item.finished_at);
        let deadlineDate = '';
        if (item.finished_at == null) {
            deadlineDate = 'No deadline yet';
        } else {
            deadlineDate = 'Deadline: '+finishDate.toDateString();
        }

        return (
            <div>
                <Helmet>
                    <title>{item.title} || Coinscope App</title>
                    <meta name="description" content={item.short_description} />
                    <link rel="canonical" href={url} />
                    <meta property="og:title" content={item.title} />
                    <meta property="og:description" content={item.short_description} />
                    <meta property="og:image" content={server+item.image} />
                    <meta property="og:url" content={url} />
                    <meta property="og:type" content="article" />
                    <meta name="twitter:title" content={item.title} />
                    <meta name="twitter:description" content={item.short_description} />
                    <meta name="twitter:image" content={server+item.image} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "${item.title}",
                        "image": "${server+item.image}",
                        "datePublished": "${item.created_at}",
                        "dateModified": "${item.created_at}",
                        "author": [{
                        "@type": "Person",
                        "name": "Coinscope App",
                        "url": "https://coinscope.app"
                    },{
                        "@type": "Person",
                        "name": "Coinscope App",
                        "url": "https://coinscope.app"
                    }]
                    }
                `}</script>
                </Helmet>
                <Box sx={{flexGrow: 1}} p={1}>
                    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                        <Grid item xs={12} lg={12}>
                            <img
                                src={item.image}
                                width={imageWidth}
                                alt={item.title}
                                loading="lazy"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <h2 className={styles.blogArticleHeader}>{item.title}</h2>
                            <small className={styles.blogArticleDate}>Added at: {publishedDate.toDateString()}</small> <br />
                            <small className={styles.blogArticleDate}>{deadlineDate}</small>
                        </Grid>

                        <Grid item xs={12} lg={12} className={styles.blogArticleText}>
                            <p>{parse(item.text)}</p>
                        </Grid>

                        <Grid item xs={12} lg={12} className={styles.blogArticleText}>
                            <h3>Follow us!</h3>
                            <a href={'https://t.me/coinscopeapp'} target={'_blank'}><Telegram style={{ color: 'white', fontSize: 40 }}/></a>
                            <a href={'https://twitter.com/coinscopeapp'} target={'_blank'}> <Twitter style={{ color: 'white', fontSize: 40 }}/></a>
                            <a href={'https://www.youtube.com/@Coinscopeapp'} target={'_blank'}> <YouTube style={{ color: 'white', fontSize: 40 }}/></a>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}
export default AirdropPage;